import { configureStore } from '@reduxjs/toolkit';
import websocketMiddleware from './middleware/websocketMiddleware';
import systemReducer from './slices/systemSlice';

const store = configureStore({
  reducer: {
    systemReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(websocketMiddleware)
});

export default store;
