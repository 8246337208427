import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { updateUserCurrencies } from 'redux/slices/systemSlice';
import { GET_USER_CURRENCIES } from '../constants/aryGraphqlSchemas';
import { useAryLazyQuery } from '../hooks/useAryGraphql';

const ShowCurrenciesContext = createContext();

function ShowCurrenciesContextProvider({ children }) {
  const reduxStore = useStore();

  const [userCurrencies, setUserCurrencies] = useState([]);

  const [getUserCurrencies] = useAryLazyQuery({
    gqlConfig: GET_USER_CURRENCIES,
    items: `
      code
      title
      decimalPlaces
    `
  });

  useEffect(() => {
    reduxStore.subscribe(async () => {
      const { doGetUserCurrencies } = reduxStore.getState().systemReducer;
      if (doGetUserCurrencies) {
        const resp = await getUserCurrencies();
        setUserCurrencies(resp.data);
        reduxStore.dispatch(updateUserCurrencies(false));
      }
    });
  }, []);

  return (
    <ShowCurrenciesContext.Provider value={userCurrencies}>
      {children}
    </ShowCurrenciesContext.Provider>
  );
}

export const useShowCurrenciesContext = () => useContext(ShowCurrenciesContext);
export default ShowCurrenciesContextProvider;
