import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from 'spec';

const BranchConnectionVerification = ({
  applicationContentType,
  contentStatus
}) => {
  const navigate = useNavigate();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  const [username, setUsername] = useState('');
  const aryauth = JSON.parse(localStorage.getItem('aryauth'));

  useEffect(() => {}, [username, aryauth?.tkrs?.access_token]);

  useEffect(() => {
    if (!aryauth?.tkrs?.access_token) return;

    authService.getUserName().then((data) => setUsername(data));

    if (contentStatus === applicationContentType) {
      const { token } = params;
      (async () => {
        //   const isValid = await window.crypto.subtle.verify(
        //     {
        //       name: 'ECDSA',
        //       hash: { name: 'SHA-384' }
        //     },
        //     publicKey,
        //     signature,
        //     new TextEncoder().encode(plainText)
        //   );
        const isValid = true;
        if (isValid) {
          navigate(`../${username}/dashboard/global-ledger/branch/list`, {
            state: {
              token
            }
          });
        }
      })();
    }
  }, [contentStatus, username, aryauth?.tkrs?.access_token]);

  return <AryLoadingAnimation />;
};

export default BranchConnectionVerification;
